import {
  Status,
  ACTION_SUCCESS,
  RESOURCE_NOTFOUND,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  SearchServiceParam, SearchServiceResult,
  GetServiceParam, GetServiceResult,
} from "../Service"

type ServiceItem = {
  slug: string
  name: string
  shortName?: string
  description?: string
  thumbnail: string
  primary?: string
  icon: string
  darkIcon: string
  categories: string[]
  meta: Map<string, any>,
  advantages: AdvantageItem[]
}

type AdvantageItem = {
  name: string
  description: string
}

export class Service {

  private services: ServiceItem[] = []

  constructor() {
    this.services = [
      {
        slug: "storage-handling",
        name: "Storage and Handling",
        shortName: "Storage and Handling",
        description: "",
        thumbnail: `/image/container-thumbnail.png`,
        primary: `/image/container-thumbnail.jpg`,
        icon: `/image/shelves-icon.svg`,
        darkIcon: `/image/shelves-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([]),
        advantages: [],
      },
      {
        slug: "repair-maintenance",
        name: "Repair and Maintenance",
        shortName: "Repair and Maintenance",
        description: "",
        thumbnail: `/image/container-thumbnail.png`,
        primary: `/image/container-thumbnail.jpg`,
        icon: `/image/tools-icon.svg`,
        darkIcon: `/image/tools-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([]),
        advantages: [],
      },
      {
        slug: "data-interchange-solutions",
        name: "Data Interchange Solutions",
        shortName: "Data Interchange Solutions",
        description: "",
        thumbnail: `/image/container-thumbnail.png`,
        primary: `/image/container-thumbnail.jpg`,
        icon: `/image/coins-icon.svg`,
        darkIcon: `/image/coins-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([]),
        advantages: [],
      },
      {
        slug: "container-customized-solution",
        name: "Customized Solution",
        shortName: "Customized Solution",
        description: "",
        thumbnail: `/image/container-thumbnail.png`,
        primary: `/image/container-thumbnail.jpg`,
        icon: `/image/dashboard-icon.svg`,
        darkIcon: `/image/dashboard-icon-dark.svg`,
        categories: ["container"],
        meta: new Map([]),
        advantages: [],
      },

      {
        slug: "inventory-management",
        name: "Inventory Management",
        description: "Effective inventory management is essential for business success. We provide comprehensive solutions to optimize your supply chain and achieve operational excellence.",
        thumbnail: `/image/inventory-management.png`,
        primary: `/image/inventory-management-primary.jpg`,
        icon: `/image/package-search-icon.svg`,
        darkIcon: `/image/package-search-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Let Allied Containers optimize your inventory with our expert solutions. Contact us today to streamline your operations."],
        ]),
        advantages: [
          {
            name: "Strategic Inventory Planning",
            description: "We work with you to develop a tailored inventory strategy that aligns with your business objectives and market demands.",
          },
          {
            name: "Accurate Inventory Control",
            description: "Our advanced systems ensure precise inventory tracking and management, preventing stock outs and overstocking.",
          },
          {
            name: "Demand Forecasting",
            description: "We analyze historical data and market trends to optimize inventory levels and minimize carrying costs.",
          },
          {
            name: "Warehouse Optimization",
            description: "We maximize warehouse space utilization and improve order fulfillment efficiency.",
          },
          {
            name: "Supply Chain Visibility",
            description: "Gain complete control over your inventory with real-time data and reporting.",
          },
        ],
      },
      {
        slug: "cross-docking",
        name: "Cross-Docking",
        shortName: "Cross-Docking",
        description: "Cross-docking eliminates the need for long-term storage, expediting the movement of goods from inbound to outbound transportation.",
        thumbnail: `/image/cross-docking.png`,
        primary: `/image/cross-docking-primary.jpg`,
        icon: `/image/truck-phosphor-icon.svg`,
        darkIcon: `/image/truck-phosphor-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Ship smarter, not harder. Our cross-docking services optimize your supply chain for maximum efficiency Contact us."],
        ]),
        advantages: [
          {
            name: "Reduced Inventory Costs",
            description: "Minimize storage expenses and free up valuable warehouse space by bypassing traditional storage methods.",
          },
          {
            name: "Scalability",
            description: "Our cross-docking solutions can adapt to your changing business needs, ensuring flexibility and growth.",
          },
          {
            name: "Enhanced Operational Efficiency",
            description: "Streamline your supply chain operations, reducing handling time and labor costs.",
          },
          {
            name: "Increased Customer Satisfaction: ",
            description: "Meet customer demands faster with reduced order processing times and timely deliveries.",
          },
          {
            name: "Faster Delivery Times",
            description: "Expedite order fulfillment and reduce lead times through swift product movement from inbound to outbound transportation",
          }
        ],
      },
      {
        slug: "coldchain-logistic",
        name: "Cold Chain Logistics",
        shortName: "Cold Chain Logistics",
        description: "Ensuring the integrity of temperature-sensitive products throughout your supply chain is crucial. We offers comprehensive cold chain logistics solutions to keep your perishable goods fresh and protected, from origin to destination.",
        thumbnail: `/image/cold-chain-logistic.png`,
        primary: `/image/cold-chain-logistic-primary.jpg`,
        icon: `/image/thermometer-snowflake-icon.svg`,
        darkIcon: `/image/thermometer-snowflake-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Need cold chain delivery? We keep your perishables fresh! Get a quote & relax. Contact us!"],
          ["ctaDescription", "Our inland transportation specialists are here to answer your questions and guide you through the process. Let us show you how Allied Containers can connect your cargo seamlessly across land and ensure your supply chain runs smoothly."],
        ]),
        advantages: [
          {
            name: "Specialized Equipment",
            description: "Our fleet includes refrigerated trucks, containers, and warehousing facilities to maintain optimal temperatures.",
          },
          {
            name: "Temperature Control Expertise",
            description: "Our team has the knowledge and experience to manage temperature-sensitive cargo effectively.",
          },
          {
            name: "Real-Time Tracking",
            description: "Track the temperature and location of your shipment throughout its journey for complete peace of mind.",
          },
          {
            name: "Uninterrupted Cold Chain",
            description: "We ensure seamless cold chain management across all transportation modes (air, sea, land).",
          },
          {
            name: "Reduced Spoilage",
            description: "Minimize product loss and ensure your goods arrive fresh and ready for sale.",
          },
        ],
      },
      {
        slug: "last-mile-delivery",
        name: "Last-Mile Delivery",
        shortName: "Last-Mile Delivery",
        description: "The final leg of your delivery journey matters most. Allied Containers provides reliable and efficient last-mile delivery solutions to ensure your products reach your customers quickly and flawlessly.",
        thumbnail: `/image/last-mile-delivery.png`,
        primary: `/image/last-mile-delivery-primary.jpg`,
        icon: `/image/delivery-drone-icon.svg`,
        darkIcon: `/image/delivery-drone-icon-dark.svg`,
        categories: ["warehouse"],
        meta: new Map([
          ["ctaTitle", "Last stop worries? We handle last-mile delivery efficiently! Get a quote & relax. Contact us!"],
          ["ctaDescription", "Our ocean freight specialists are here to answer your questions and guide you through the process. We'll help you choose the most suitable FCL or LCL option for your specific needs and ensure your cargo arrives safely at its destination."],
        ]),
        advantages: [
          {
            name: "Speed & Efficiency",
            description: "We prioritize fast and reliable delivery to keep your customers satisfied.",
          },
          {
            name: "Flexible Scheduling",
            description: "Choose the delivery timeframe that best suits your customers' needs.",
          },
          {
            name: "Real-Time Tracking",
            description: "Both you and your customers can track the shipment's progress in real-time for complete peace of mind.",
          },
          {
            name: "Proven Track Record",
            description: "Our experienced team has a proven record of successful last-mile deliveries.",
          },
          {
            name: "Multiple Delivery Options",
            description: "We offer a variety of delivery options to cater to your specific needs (e.g., signature required, same-day delivery).",
          },
        ],
      },


      {
        slug: "air-freight",
        name: "Air Freight",
        shortName: "Air Freight",
        description: "Need to get your cargo across the globe quickly? Allied Containers offers air freight solutions designed for speed and efficiency.",
        thumbnail: `/image/air-freight.png`,
        primary: `/image/air-freight-primary.jpg`,
        icon: `/image/plane-icon.svg`,
        darkIcon: `/image/plane-icon-dark.svg`,
        categories: ["freight"],
        meta: new Map([
          ["ctaTitle", "Our air freight gets your cargo across borders ASAP. Contact us!"],
          ["ctaDescription", "Whether you're shipping time-sensitive documents, high-value goods, or temperature-controlled products, Allied Containers has the air freight solution tailored to your needs."],
        ]),
        advantages: [
          {
            name: "Time-Critical Delivery",
            description: "We prioritize speed, ensuring your urgent shipments reach their destination on time.",
          },
          {
            name: "Global Network",
            description: "Our extensive network of airlines allows us to offer competitive rates and flexible routing options.",
          },
          {
            name: "Expert Handling",
            description: "Our experienced team ensures proper documentation, secure packing, and seamless customs clearance.",
          },
          {
            name: "Real-Time Tracking",
            description: "Track your shipment's progress every step of the way for complete peace of mind.",
          },
          {
            name: "Effective Solutions",
            description: "We work with you to find the most efficient air freight option for your specific needs.",
          }
        ],
      },
      {
        slug: "ocean-freight",
        name: "Ocean Freight (FCL & LCL)",
        shortName: "Ocean Freight",
        description: "Whether you're shipping large volumes of cargo or need a cost-effective option for smaller shipments, Allied Containers offers flexible ocean freight solutions to meet your needs.",
        thumbnail: `/image/ocean-freight.png`,
        primary: `/image/ocean-freight-primary.jpg`,
        icon: `/image/cargo-boat-icon.svg`,
        darkIcon: `/image/cargo-boat-icon-dark.svg`,
        categories: ["freight"],
        meta: new Map([
          ["ctaTitle", "Need ocean freight? FCL or LCL, we handle it all! Get a quote & ship globally. Contact us!"],
          ["ctaDescription", "Our ocean freight specialists are here to answer your questions and guide you through the process. We'll help you choose the most suitable FCL or LCL option for your specific needs and ensure your cargo arrives safely at its destination."],
          ["note", `<p class="font-medium text-xl">FCL (Full Container Load) - Ideal for large shipments</p><ul class="pl-4 list-outside list-disc flex flex-col gap-2"><li>Maximize efficiency and cost savings for high-volume cargo.</li><li>Secure dedicated containers for complete control and faster delivery.</li></ul><p class="font-medium text-xl">LCL (Less-than-Container Load) - Perfect for smaller shipments</p><ul class="pl-4 list-outside list-disc flex flex-col gap-2"><li>Share container space for a cost-effective option.</li><li>Ideal for businesses just starting out or with fluctuating needs.</li></ul>`],
        ]),
        advantages: [
          {
            name: "Competitive Rates",
            description: "Benefit from our strong carrier relationships and global network to secure the best prices.",
          },
          {
            name: "Expert Route Planning",
            description: "Our experienced team optimizes shipping routes for efficiency and cost-effectiveness.",
          },
          {
            name: "Seamless Documentation",
            description: "We handle all necessary paperwork to ensure smooth customs clearance.",
          },
          {
            name: "Real-Time Tracking",
            description: "Monitor your shipment's progress every step of the way for complete peace of mind.",
          },
          {
            name: "Global Network",
            description: "Our extensive network of ports and partners ensures seamless delivery worldwide.",
          },
        ],
      },
      {
        slug: "custom-clearance",
        name: "Customs Clearance",
        shortName: "Customs Clearance",
        description: "Navigating customs regulations can feel like a maze. At Allied Containers, we take the stress out of the process with our efficient and reliable customs clearance services.",
        thumbnail: `/image/custom-clearance.png`,
        primary: `/image/custom-clearance-primary.jpg`,
        icon: `/image/multiple-file-icon.svg`,
        darkIcon: `/image/multiple-file-icon-dark.svg`,
        categories: ["freight"],
        meta: new Map([
          ["ctaTitle", "Stuck at customs? We clear your imports & exports fast & easy. Get a quote today!"],
          ["ctaDescription", "Whether you're shipping time-sensitive documents, high-value goods, or temperature-controlled products, Allied Containers has the air freight solution tailored to your needs."],
        ]),
        advantages: [
          {
            name: "Expert Guidance",
            description: "Our experienced customs brokers handle all the paperwork and ensure your shipments comply with regulations.",
          },
          {
            name: "Seamless Processing",
            description: "We work closely with customs authorities to expedite clearance and minimize delays.",
          },
          {
            name: "Reduced Costs",
            description: "Benefit from our expertise to avoid costly penalties or hold-ups.",
          },
          {
            name: "Global Network",
            description: "Leverage our connections to navigate customs procedures worldwide.",
          },
          {
            name: "Real-Time Tracking",
            description: "Stay informed every step of the way with clear communication and shipment tracking.",
          },
        ],
      },

      {
        slug: "warehouse-management-system",
        name: "Warehouse Management System",
        description: "Efficient warehousing and distribution are the backbones of a successful supply chain. At Allied Containers, we offer comprehensive solutions tailored to your specific needs.",
        thumbnail: `/image/warehouse-management-system.png`,
        primary: `/image/warehousing-distribution-primary.jpg`,
        icon: `/image/warehouse-icon.svg`,
        darkIcon: `/image/warehouse-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Our experts are ready to help. Get in touch and we'll find the solution you need."],
        ]),
        advantages: [
          {
            name: "Strategic Locations",
            description: "Our strategically located warehouses provide easy access to major transportation hubs, reducing transit times and costs."
          },
          {
            name: "Flexible Storage Solutions",
            description: "Whether you need bulk storage or precise inventory management, our customizable solutions cater to your specific requirements."
          },
          {
            name: "Uncompromising Security",
            description: "Your goods are safe with us. Our warehouses are equipped with advanced security systems to safeguard your valuable inventory."
          },
          {
            name: "Last Mile Mastery",
            description: "We offer reliable last-mile delivery services, ensuring your products reach customers on time and in perfect condition."
          },
          {
            name: "Real-time Visibility",
            description: "Gain complete control over your supply chain with our real-time tracking and reporting tools."
          }
        ],
      },
      {
        slug: "transport-management-system",
        name: "Transport Management System",
        description: "Efficient warehousing and distribution are the backbones of a successful supply chain. At Allied Containers, we offer comprehensive solutions tailored to your specific needs.",
        thumbnail: `/image/transport-management-system.png`,
        primary: `/image/warehousing-distribution-primary.jpg`,
        icon: `/image/cargo-transport-icon.svg`,
        darkIcon: `/image/cargo-transport-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Our experts are ready to help. Get in touch and we'll find the solution you need."],
        ]),
        advantages: [
          {
            name: "Strategic Locations",
            description: "Our strategically located warehouses provide easy access to major transportation hubs, reducing transit times and costs."
          },
          {
            name: "Flexible Storage Solutions",
            description: "Whether you need bulk storage or precise inventory management, our customizable solutions cater to your specific requirements."
          },
          {
            name: "Uncompromising Security",
            description: "Your goods are safe with us. Our warehouses are equipped with advanced security systems to safeguard your valuable inventory."
          },
          {
            name: "Last Mile Mastery",
            description: "We offer reliable last-mile delivery services, ensuring your products reach customers on time and in perfect condition."
          },
          {
            name: "Real-time Visibility",
            description: "Gain complete control over your supply chain with our real-time tracking and reporting tools."
          }
        ],
      },
      {
        slug: "depot-management-system",
        name: "Depot Management System",
        description: "Efficient warehousing and distribution are the backbones of a successful supply chain. At Allied Containers, we offer comprehensive solutions tailored to your specific needs.",
        thumbnail: `/image/depot-management-system.png`,
        primary: `/image/warehousing-distribution-primary.jpg`,
        icon: `/image/container-icon.svg`,
        darkIcon: `/image/container-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Our experts are ready to help. Get in touch and we'll find the solution you need."],
        ]),
        advantages: [
          {
            name: "Strategic Locations",
            description: "Our strategically located warehouses provide easy access to major transportation hubs, reducing transit times and costs."
          },
          {
            name: "Flexible Storage Solutions",
            description: "Whether you need bulk storage or precise inventory management, our customizable solutions cater to your specific requirements."
          },
          {
            name: "Uncompromising Security",
            description: "Your goods are safe with us. Our warehouses are equipped with advanced security systems to safeguard your valuable inventory."
          },
          {
            name: "Last Mile Mastery",
            description: "We offer reliable last-mile delivery services, ensuring your products reach customers on time and in perfect condition."
          },
          {
            name: "Real-time Visibility",
            description: "Gain complete control over your supply chain with our real-time tracking and reporting tools."
          }
        ],
      },
      {
        slug: "software-customized-solution",
        name: "Customized Solution",
        description: "",
        thumbnail: `/image/depot-management-system.png`,
        primary: `/image/warehousing-distribution-primary.jpg`,
        icon: `/image/dashboard-icon.svg`,
        darkIcon: `/image/dashboard-icon-dark.svg`,
        categories: ["software"],
        meta: new Map([
          ["ctaTitle", "Our experts are ready to help. Get in touch and we'll find the solution you need."],
        ]),
        advantages: [],
      },
    ]
  }

  public async SearchService(p: SearchServiceParam): Promise<SearchServiceResult> {
    try {
      const {
        page,
        total_items,
        categories = [],
      } = p

      let services = this.services
      if (Array.isArray(categories) && categories.length > 0) {
        const categoryDict = new Set<string>()
        categories.forEach((category) => {
          categoryDict.add(category)
        })

        services = services.filter((service) => {
          return service.categories.some((category) => {
            return categoryDict.has(category)
          })
        })
      }

      const totalItems = services.length

      if (Number.isInteger(page) && Number.isInteger(total_items)) {
        const last = page * total_items
        const first = last - total_items

        services = services.slice(first, last)
      }

      return {
        success: new Status("success search service", ACTION_SUCCESS),
        data: {
          summary: {
            page,
            total_items: totalItems
          },
          items: services
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetService(p: GetServiceParam): Promise<GetServiceResult> {
    try {
      const service = this.services.find((service) => {
        return service.slug === p.slug
      })
      if (!service) {
        return {
          error: new Status("service not available", RESOURCE_NOTFOUND),
        }
      }

      return {
        success: new Status("success get service", ACTION_SUCCESS),
        data: service
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}
